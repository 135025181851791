import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { suspendUserHandler, deleteUserHandler, apiClearUser, apiGetUsers } from '../../services/UsersService';
import Alerts from 'utils/Alerts.js';
import { ActionPopupContext } from 'contexts/Action_Modal_Context';
import { Toolbar, Paper, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import DataTable from 'components/tables/DataTable.js';
import Images from 'utils/Images.js';
import Drawer from '@mui/material/Drawer';
import UserDetails from 'drawers/UserDetails.js';
import DataFilter from 'components/filters/DataFilter.js';
import SearchBySelect from 'components/inputs/SearchBySelect.js';
import SearchField from 'components/inputs/SearchField.js';
import { UserContext } from 'contexts/UserContext.js';
import Alert from '@mui/material/Alert';

const content = require('content.json');
const usersActions = content.users_actions;

const configuration = require('configuration.json');
const paths = configuration.paths;
const userStatues = configuration.user_statuses;

const initialFilter = {
    order: "desc",
    orderBy: "dateCreated",
    page: 1,
    pageSize: 6,
    search: "",
    searchBy: ""
};


const UsersTable = () => {
    const { handleActionPopupOpen } = useContext(ActionPopupContext);
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [totalRows, setTotalRows] = useState(0);
    const [loader, setLoader] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [adminComments, setAdminComments] = useState(null)


    const columns = [
        {
            label: 'ID',
            id: 'ID',
            width: 50,
        },
        {
            label: 'UniqueID',
            id: 'uniqueID',
            filterable: true,
            width: 180,
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        handleRedirectToUser({ userDetails: row });
                    }}>
                        {val}
                    </span>
                );
            }
        },
        {
            label: 'Username',
            id: 'username',
            filterable: true,
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        handleRedirectToUser({ userDetails: row });
                    }}>
                        {val}
                    </span>
                );
            }
        },
        {
            label: 'Email',
            id: 'email',
            filterable: true,
        },
        {
            label: 'Platform joined',
            id: 'platformJoined',
            filterable: true,
        },
        {
            label: 'Lifetime coins amount',
            id: 'lifetimeCoinsAmount',
            filterable: true,
        },
        {
            label: 'Place',
            id: 'place',
            filterable: true,
        },
        {
            label: 'Date Created',
            id: 'dateCreated',
            filterable: true,
        },
        {
            label: 'Status change comments',
            id: 'adminComments',
            filterable: false,
        },
        {
            label: 'Notes Count',
            id: 'notesCount',
            filterable: true,
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        handleRedirectToUser({ userDetails: { ...row, scrollDown: true } });
                    }}>
                        {val ? val +' - see' : ''} 
                    </span>
                );
            }
        },
        {
            label: 'Status',
            id: 'userStatus',
            filterable: true,
            parse: (val, row) => {
                return (
                    <Alert
                        style={{ width: 140 }}
                        severity={
                            val === 'deleted' ? 'error' : val === 'active' ? 'success' : 'warning'
                        }
                    >
                        {val}
                    </Alert>
                );
            }
        },
    ];


    const getUsers = async () => {
        setLoader(true);
        const result = await apiGetUsers(filter);
        setUsers([...result?.users]);
        setSelectedUser(null);
        setTotalRows(result.total);
        setLoader(false);
    };

    useEffect(() => {
        getUsers();
    }, [filter]);


    const handleAction = async (actionType) => {

        if (usersActions[actionType] === usersActions.delete) {
            deleteUserHandler(setLoader, selectedUser, handleActionPopupOpen, () => { getUsers(); }, adminComments,);
        }
        else if (usersActions[actionType] === usersActions.suspend) {
            suspendUserHandler(setLoader, selectedUser, userStatues.suspended, handleActionPopupOpen, () => { getUsers(); }, adminComments,);
        }
        else if (usersActions[actionType] === usersActions.unsuspend) {
            suspendUserHandler(setLoader, selectedUser, userStatues.active, handleActionPopupOpen, () => { getUsers(); }, adminComments,);
        }
        else if (usersActions[actionType] === usersActions.clear) {
            await apiClearUser(selectedUser, false);
            getUsers();
        }
        else if (usersActions[actionType] === usersActions.complete_offer) {
            Alerts.showUserOfferCompleteAlert(selectedUser, (value) => console.log(value));
        }

    };
    

    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    };


    const onUserSelect = userArr => {

        const idx = userArr[0] ?? null;
        if (idx !== null) {
            setSelectedUser(users[idx].ID);
            setAdminComments(users[idx].adminComments)
        } else {
            setSelectedUser(null);
        }
    };


    const openDrawer = row => {
        setUserDetails(row);
        setUser(row);
        setDrawerOpen(true);
    };

    const handleRedirectToUser = ({ userDetails }) => {
        setUser(userDetails);
        navigate(`${paths.user_path}?uniqueUserID=${userDetails.uniqueID}`);
    };

    return (
        <div className="content-container">
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            justifyContent: "start",
                            columnGap: 1
                        }}
                    >
                        <Box className='flex gap-10'>
                            <img src={Images.imageURL('rectangle.svg')} alt="" />
                            <h3>Users</h3>
                        </Box>
                        <Box>
                            {selectedUser && (
                                <FormControl size="small" sx={{ minWidth: 150, textAlign: 'left' }}>
                                    <InputLabel>Action</InputLabel>
                                    <Select
                                        size="small"
                                        onChange={event => {
                                            handleAction(event.target.value);
                                        }}
                                        label="Action"
                                        defaultValue=""
                                    >
                                        {Object.keys(usersActions).map((item, idx) => (
                                            <MenuItem value={item} key={idx}>
                                                {usersActions[item]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>

                        <DataFilter onChange={onFilterChange} submitLabel="Submit">
                            <SearchBySelect data={columns} />
                            <SearchField />
                        </DataFilter>
                    </Toolbar>

                    <DataTable
                        columns={columns}
                        tableData={users}
                        onFilterChange={onFilterChange}
                        filter={filter}
                        loading={loader}
                        total={totalRows}
                        checkboxSelection
                        onRowSelectChange={onUserSelect}
                        forceClearSelection={!Boolean(selectedUser)}
                        fullHeight
                    />
                </Paper>
            </Box>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <UserDetails user={userDetails} />
            </Drawer>
        </div>
    );

};

export default UsersTable;