import Backend from 'Backend.js';


export const apiTestEmails = async ({ notificationType, uniqueUserID, uniqueOfferID }) => {

    const res = await fetch(Backend.backendURLs.testsEmailsURL, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ notificationType, uniqueUserID, uniqueOfferID }),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

};