import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, styled } from '@mui/material';
import Header from 'components/text/Header.js';
import EmailTests from 'components/tests/EmailTests';
import { useState } from 'react';

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0,
}));

function Tests() {
    const [currentTab, setCurrentTab] = useState('1');

    const onTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <div className="tests-page">
            <Header />
            <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={onTabChange}
                        variant="scrollable"
                        scrollButtons={true}
                    >
                        <Tab label="Mails" value="1" />

                    </TabList>
                </Box>
                <StyledTabPanel value="1">
                    <EmailTests/>
                </StyledTabPanel>
            </TabContext>
        </div>
    );
}

export default Tests;