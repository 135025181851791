import Backend from 'Backend';

export const apiGetPositions = async () => {

    const res = await fetch(Backend.backendURLs.offersPositionUrl, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiUpdateOfferPosition = async (offerID, position, notifyActiveUsers) => {

    const res = await fetch(Backend.backendURLs.offersUpdatePositionUrl, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ offerID, position, notifyActiveUsers }),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiUpdateOffer = async (offerID, data) => {

    const res = await fetch(Backend.backendURLs.offerUpdateUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            offerID,
            ...data
        }),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiAddNewOffer = async ( props ) => {

    const res = await fetch(Backend.backendURLs.addNewOfferUrl, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify(props),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}



export const apiGetOfferById = async (params) => {

    const queryString = new URLSearchParams(params).toString();

    const res = await fetch(`${Backend.backendURLs.getOfferByIdURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiGetOfferStockAttributes = async (uniqueOfferID) => {

    const res = await fetch(`${Backend.backendURLs.offerStockAttributeURL}?uniqueOfferID=${uniqueOfferID}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiSaveOfferStockattribute = async (uniqueOfferID, attribute) => {

    const res = await fetch(Backend.backendURLs.offerStockAttributeURL, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            uniqueOfferID,
            attributeID: attribute?.ID,
            heading: attribute.Heading,
            attributeMeaning: attribute.AttributeMeaning,
            valuesList: attribute.ValuesList
        }),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiSaveOfferCombination = async (uniqueOfferID, combinationID, combination) => {

    const res = await fetch(Backend.backendURLs.offerStockCombinationURL, {
        method: Backend.backendMethods.put,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            uniqueOfferID,
            combinationID,
            coins: combination.Coins,
            clickthroughCap: combination.ClickthroughCap,
            clickthroughCapDuration: combination.ClickthroughCapDuration,
            completionsCap: combination.CompletionsCap,
            completionsCapDuration: combination.CompletionsCapDuration
        }),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiSyncOfferCombination = async (uniqueOfferID) => {

    const res = await fetch(Backend.backendURLs.syncOfferStockCombinationURL, {
        method: Backend.backendMethods.put,
        headers: Backend.generateHeader(),
        body: JSON.stringify({uniqueOfferID}),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiDeleteOfferStockattribute = async (uniqueOfferID, attributeID) => {

    const res = await fetch(Backend.backendURLs.offerStockAttributeURL, {
        method: Backend.backendMethods.delete,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            uniqueOfferID,
            attributeID
        }),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiGetOfferStockCombinations = async (uniqueOfferID) => {

    const res = await fetch(`${Backend.backendURLs.getOfferStockCombinationsURL}?uniqueOfferID=${uniqueOfferID}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}



export const apiUpdateOfferVideoGuideline = async ( props ) => {

    const res = await fetch(Backend.backendURLs.updateOfferVideoGuidelineURL, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify(props),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiGetSecondConversions = async (params) => {

    const queryString = new URLSearchParams(params).toString();

    const res = await fetch(`${Backend.backendURLs.secondConversionsURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}

export const apiSetSecondConversion = async (props) => {
    const {uniqueOfferID, targetUniqueOfferID, action, secondConversionID, startRange, endRange} = props;
    
    const res = await fetch(Backend.backendURLs.secondConversionsURL, {
        method: Backend.backendMethods.put,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            uniqueOfferID, 
            targetUniqueOfferID, 
            action,
            secondConversionID,
            startRange,
            endRange
        }),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

};

export const apiGetOffers = async (filter, page) => {
    try {
        const queryParams = new URLSearchParams({ ...filter, page }).toString();
        const res = await fetch(`${Backend.backendURLs.listOffersURL}?${queryParams}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        }

        return res;
    } catch (err) {
        return err; 
    }
};
