import React from 'react';

function ActivateOfferSuccess({ moreOffersToApprove, handleCancel }) {
    return (
        <div className='margin-top-20 '>
            <p className='margin-bottom-20'>
                {`Offer activated success. More offers to approve for this bundle:`}
                &nbsp;
                <span className='font bold font-20'>{`${moreOffersToApprove}`}</span>
            </p>
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>OK</button>
        </div>
    );
}

export default ActivateOfferSuccess;