import React from 'react';
import { ShineBorder } from '../widgets/Shine_Border';
import EmailTestForm from './components/EmailTestForm';
import { Typography } from '@mui/material';

function EmailTests() {
    return (
        <div className='email-tests-tab'>
            <div className="email-tests-form">
                <EmailTestForm />
            </div>
            <div className='email-tests-rules-container'>
                <ShineBorder>
                    <div className="email-tests-rules">
                        <Typography variant="h5" sx={{ marginBottom: "20px", textAlign: "center" }}>Email Testing Rules</Typography>
                        <ul className="rules-list">
                            <li>
                                <span className="rule-icon">✔️</span>
                                Before testing the emails, Admin needs to make sure that the user email configuration in the Account settings page allows sending emails.
                            </li>
                            <li>
                                <span className="rule-icon">✔️</span>
                                The user needs to login or complete an offer in the last 24 hours.
                            </li>
                        </ul>
                    </div>
                </ShineBorder>
            </div>

        </div>
    );
}

export default EmailTests;