import React, {  useState  } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Button, MenuItem, Select, TextField, Checkbox, FormControlLabel  } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import dayjs from 'dayjs';
import Date from 'utils/Date.js';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import debounce from 'utils/Debounce';

const today = dayjs();

const initialFilter = {
  filterQuery: '', 
  filterBy: '', 
  period: '', 
  status: '', 
  platform: '', 
  country: '',
  bestOffer: ''
}

const GridForm = ({ 
  dateSelection,
  searchBy,
  periodFilter, 
  providerFilter,
  platformFilter, 
  statusFilter, 
  countriesFilter,
  columnFilter, 
  handleSearchChange,
  offersDoneFilter,
  offersDoneFilterChange,
  bestOffersFilter,
  onNewOffer,
  updateItems,
}) => {
  const defaultProvider = providerFilter?.[0];
  const [provider, setProvider] = useState(defaultProvider);
  const [filter, setFilter] = useState(initialFilter);

  const [startDateValue, setStartDateValue] = useState(dayjs(offersDoneFilter?.startDate));
  const [endDateValue, setEndDateValue] = useState(dayjs(offersDoneFilter?.endDate));

  const providerChangeHandler = (newProvider) =>  {
      setProvider(newProvider);
      setStartDateValue(null);

      offersDoneFilterChange({ ...offersDoneFilter, providerID: newProvider.ProviderID });
  }

  const handleChangeStartDate = (value) => {
      const { startDate } = Date.yearMMDDFormatter(value, null);
      setStartDateValue(value);
      offersDoneFilterChange({ ...offersDoneFilter, startDate });
  }

  const handleChangeEndDate = (value) => {
    const { endDate } = Date.yearMMDDFormatter(null, value);
      setEndDateValue(value);
      offersDoneFilterChange({ ...offersDoneFilter, endDate });
  }

  return (
    <div className="grid-list_flex-form">
      {dateSelection && (
        <div className='flex gap-10'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer 
                  components={['DatePicker']} 
                  sx={{
                    "&": {
                      overflow: 'visible'
                    }
                  }}
              >
                  <DatePicker 
                      views={['year', 'month', 'day']}
                      label="Start"
                      format="DD.MM"
                      maxDate={today}
                      value={startDateValue}
                      onChange={handleChangeStartDate}
                      slotProps={{
                          textField: {
                              readOnly: true,
                          },
                      }}
                      sx={{
                          "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                            top: -8,
                          },
                          "& input": {
                              padding: '8px 14px',
                          },
                          "&": {
                              fontFamily: 'inherit',
                              width: 'fit-content',
                              minWidth: '140px !important',
                          },
                          "& *": {
                              fontFamily: 'inherit',
                          },
                          marginTop: "-8px",
                          maxWidth: 70,
                      }}
                  />
              </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer 
                  components={['DatePicker']} 
                  sx={{
                    "&": {
                      overflow: 'visible'
                    }
                  }}
              >
                  <DatePicker
                      label="End" 
                      format="DD.MM"
                      maxDate={today}
                      disabled={!startDateValue}
                      value={endDateValue}
                      onChange={handleChangeEndDate}
                      slotProps={{
                          textField: {
                              readOnly: true,
                          },
                      }}
                      sx={{
                          "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                            top: -8,
                          },
                          "& input": {
                              padding: '8px 14px',
                          },
                          "&": {
                              fontFamily: 'inherit',
                              width: 'fit-content',
                              minWidth: '140px !important',
                          },
                          "& *": {
                              fontFamily: 'inherit'
                          },
                          marginTop: "-8px",
                          maxWidth: 70,
                      }}
                  />
              </DemoContainer>
          </LocalizationProvider>
      </div>
      )}
      {columnFilter && (
        <FormControl size="small" sx={{ minWidth: 140}}>
          <Select
            defaultValue={Object.values(columnFilter)[0]}
            size="small"
            onChange={(e) => {
              handleSearchChange({ value: e.target.value !== 'Search By' ? e.target.value : '', field: 'filterBy' });
            }}
          >
            {Object.keys(columnFilter).map((item, idx) => (
              <MenuItem value={columnFilter[item]} key={idx}>
                {columnFilter[item]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {searchBy && (searchBy !== "Offers Done") && (
        <TextField
          sx={{
            "& *": {
              fontFamily: 'inherit',
            },
            minWidth: '200px'
          }}
          size="small"
          className="grid-list_flex-form_input"
          onInput={(e) => {
            debounce(handleSearchChange({ value: e.target.value, field: 'filterQuery' }), 100);
          }}
          label="Search"
          type="search"
          InputProps={{
            endAdornment: <InputAdornment position="end">{<SearchIcon style={{ opacity: 0.5 }} />}</InputAdornment>
          }}
        />
      )}
      {searchBy === 'Offers' && (
        <TextField
          sx={{
            "& *": {
              fontFamily: 'inherit',
            },
            minWidth: '200px'
          }}
          size="small"
          className="grid-list_flex-form_input"
          label="Link ID"
          type="number"
          inputProps={{
            min: 0
          }}
          onInput={(e) => {
            debounce(handleSearchChange({ value: e.target.value, field: 'linkID' }), 100);
          }}
        />
      )}
      {periodFilter && (
        <Select
          defaultValue={Object.values(periodFilter)[0]}
          size="small"
          onChange={e => {
            handleSearchChange({ value: e.target.value, field: 'period' })
          }}
        >
          {Object.keys(periodFilter).map((item, idx) => (
            <MenuItem value={periodFilter[item]} key={periodFilter[item] + idx}>
              {periodFilter[item]}
            </MenuItem>
          ))}
        </Select>
      )}
      {platformFilter && (
        <Select
          size="small"
          defaultValue={Object.values(platformFilter)[0]}
          onChange={e => {
            handleSearchChange({ value: e.target.value !== 'Platform' ? e.target.value : '', field: 'platform' })
          }}
        >
          {Object.keys(platformFilter).map((item, idx) => (
            <MenuItem value={platformFilter[item]} key={platformFilter[item] + idx}>
              {platformFilter[item]}
            </MenuItem>
          ))}
        </Select>
      )}
      {providerFilter && (
        <Select
            style={{ fontFamily: 'inherit' }}
            size="small"
            value={provider.ProviderName}
        >
            {providerFilter.map(each => (
                <MenuItem 
                    style={{ 
                        fontFamily: 'inherit' 
                    }}
                    key={each.ProviderID}
                    value={each.ProviderName} 
                    onClick={() => providerChangeHandler(each)}
                >
                    { each.ProviderName }
                </MenuItem>
            ))}
        </Select>
      )}
      {statusFilter && (
        <Select
          size="small"
          defaultValue={Object.values(statusFilter)[0]}
          onChange={e => {
            handleSearchChange({ value: e.target.value !== 'Status' ? e.target.value : '', field: 'status' });
          }}
        >
          {Object.keys(statusFilter).map((item, idx) => (
            <MenuItem value={statusFilter[item]} key={statusFilter[item] + idx}>
              {statusFilter[item]}
            </MenuItem>
          ))}
        </Select>
      )}
      {countriesFilter && (
        <Select
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300
              },
            },
          }}
          onChange={e => {
            handleSearchChange({ value: e.target.value !== 'Country' ? e.target.value : '', field: 'country' });
          }}
          size="small"
          defaultValue={Object.values(countriesFilter)[0]}
        >
          {Object.keys(countriesFilter).map((item, idx) => (
            <MenuItem value={countriesFilter[item]} key={countriesFilter[item] + idx}>
              {countriesFilter[item]}
            </MenuItem>
          ))}
        </Select>
      )}
      
      {columnFilter && (
        <Button sx={{ minWidth: '100px' }} variant="contained" onClick={updateItems}>Search</Button>
      )}
      {bestOffersFilter && (
        <FormControlLabel 
          control={<Checkbox
            onChange={event => {
              handleSearchChange({ value: (event.target.checked ? "1" : ""), field: 'bestOffer' });
            }} />} 
          sx={{minWidth:"125px", marginLeft:"30px"}} 
          label="Best Offers" 
        />
      )}
      {onNewOffer && (
        <Button sx={{minWidth: '150px'}} variant="contained" startIcon={<LibraryAddOutlinedIcon />} onClick={onNewOffer}>New Offer</Button>
      )}
    </div>
  )
}

export default GridForm
