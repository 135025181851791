import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import UserData from 'User_Data.js';
import Images from 'utils/Images.js';
import Backend from 'Backend.js';
import BugReportIcon from '@mui/icons-material/BugReport';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const content = require('content.json');
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const menuOptions = content.menu_options;
const UISettings = configuration.ui_settings;
const homeOption = menuOptions.users;
const withdrawalsOption = menuOptions.withdrawals;
const offersOption = menuOptions.offers;
const uploadTextsOption = menuOptions.uploadTexts;
const uploadFilesOption = menuOptions.uploadFiles;
const providersOption = menuOptions.providers;
const reviewsOption = menuOptions.reviews;
const testsOption = menuOptions.tests;

const menuToggleTimeout = UISettings.menu_toggle_timeout;

function Menu() {
    const [showMenu, setShowMenu] = useState(true);
    const [lastMenuUpdateTime, setLastMenuUpdateTime] = useState(null);
    const admin = UserData.getItem(userDataKeys.admin_name);
    const [adminName, setAdminName] = useState(admin);
    const { pathname } = useLocation();

    function toggleMenu() {
        if ((new Date()).getTime() - lastMenuUpdateTime < menuToggleTimeout) return;
        setShowMenu(!showMenu);
        setLastMenuUpdateTime((new Date()).getTime());
    }

    const customEventListener = event => {
        const { detail: name } = event;
        setAdminName(name);
    };

    const handleLogOut = () => {
        Backend.logOut();
    }

    useEffect(() => {
        setLastMenuUpdateTime((new Date()).getTime());
        window.addEventListener('adminNameChange', customEventListener);

        return () => {
            window.removeEventListener('adminNameChange', customEventListener);
        }
    }, []);

    return (
        <>
            <img
                id="menu-bar"
                src={Images.imageURL('menu-bar.svg')}
                alt="Open menu"
                width="30"
                height="auto"
                className={`menu-bar ${showMenu ? "hidden" : ""}`}
                onClick={toggleMenu}
            />
            <div className={`menu-drawer ${!showMenu ? "hidden" : ""}`}>
                <div className='menu-drawer-container'>
                    <div id="logo-menu-div" className="logo-menu-div">
                        <Link to={homeOption.url}>
                            <img
                                src={Images.imageURL('treat-coin-logo.svg')}
                                alt="Home"
                                width="140"
                                height="25"
                                className="cursor-pointer"
                            />
                        </Link>
                        <div
                            onClick={toggleMenu}
                            className={`exit-menu-div ${!showMenu ? 'hidden' : ''}`}
                        >
                            <img
                                src={Images.imageURL('close.svg')}
                                alt="Close menu"
                                width="18"
                                height="18"
                                className="close-menu"
                            />
                        </div>
                    </div>
                    <div className="menu-options-container">
                        <Link
                            to={homeOption.url}
                            className={pathname === "/" ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-users-icon.svg')}
                                alt={homeOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {homeOption.title}
                            </button>
                        </Link>
                        <Link
                            to={offersOption.url}
                            className={(pathname === offersOption.url) ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-offers-icon.svg')}
                                alt={offersOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {offersOption.title}
                            </button>
                        </Link>
                        <Link
                            to={providersOption.url}
                            className={(pathname === providersOption.url) ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-providers-icon.png')}
                                alt={providersOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {providersOption.title}
                            </button>
                        </Link>
                        <Link
                            to={withdrawalsOption.url}
                            className={(pathname === withdrawalsOption.url) ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-withdrawals-icon.svg')}
                                alt={withdrawalsOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {withdrawalsOption.title}
                            </button>
                        </Link>
                        <Link
                            to={uploadFilesOption.url}
                            className={(pathname === uploadFilesOption.url) ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-uploadfiles-icon.png')}
                                alt={uploadFilesOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {uploadFilesOption.title}
                            </button>
                        </Link>
                        <Link
                            to={uploadTextsOption.url}
                            className={(pathname === uploadTextsOption.url) ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-uploadtexts-icon.png')}
                                alt={uploadTextsOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {uploadTextsOption.title}
                            </button>
                        </Link>
                        <Link
                            to={reviewsOption.url}
                            className={(pathname === reviewsOption.url) ? "active" : ""}
                        >
                            <img
                                src={Images.imageURL('menu-reviews-icon.png')}
                                alt={reviewsOption.title}
                            />
                            <button variant="outlined" className="menu-option-button">
                                {reviewsOption.title}
                            </button>
                        </Link>
                        <Link
                            to={testsOption.url}
                            className={(pathname === testsOption.url) ? "active" : ""}
                        >
                            <DoneAllIcon
                                color='success'
                            />
                            <button variant="outlined" className="menu-option-button">
                                {testsOption.title}
                            </button>
                        </Link>
                    </div>
                    <div className="menu-bottom-container">
                        <div className="flex justify-between admin-card">
                            <div className="flex justify-between">
                                <div>
                                    <div className='font bold'>{ adminName }</div>
                                    <div className='font font-12'>CSM</div>
                                </div>
                            </div>
                        </div>
                        <div onClick={handleLogOut} className="flex justify-between cursor-pointer">
                            <div className='font'>{content.log_out_text}</div>
                            <img
                                src={Images.imageURL('menu-logout-icon.svg')}
                                alt="Log out"
                                className="cursor-pointer"
                                
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}

export default Menu;