import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import DataTable from '../tables/DataTable.js';
import { apiGetUserOfferCompleteHistory, apiCompleteUserOffer } from '../../services/UsersService.js';
import { apiGetOfferById } from '../../services/OfferService.js';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import { md5 } from 'js-md5';
import Paper from '@mui/material/Paper';


const content = require('content.json');

const completeOffersColumns = [
    {
        id: 'DateCreated',
        label: 'Date',
        sortable: false,
        parse: (col) => {
            return dayjs(col).format('DD-MM-YYYY HH:mm');
        }
    },
    {
        id: 'OfferID',
        label: 'OfferID / UniqueOfferID',
        sortable: false,
        width: 150,
        parse: (col, row) => {
            return `${col} ${row.UniqueOfferID}`
        }
    },
    {
        id: 'ProviderName',
        label: 'Provider Name',
        sortable: false
    },
    {
        id: 'AppName',
        label: 'App Name',
        sortable: false
    },
    {
        id: 'Message',
        label: 'Message',
        sortable: false
    }
];

const completeOffersFilter = {
    "order": "desc",
    "orderBy": "ID",
    "page": 1,
    "pageSize": 3,
}

export default function OfferCompleteModalForm({ userId, handleCancel, handleConfirm, confirmCB }) {

    const [user, setUser] = useState(userId);
    const [offer, setOffer] = useState({});
    const [completeOffersData, setCompleteOffersData] = useState([]);
    const [completeOffersLoading, setCompleteOffersLoading] = useState(false);
    const [offerSelected, setOfferSelected] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [offerSearchLoading, setOfferSearchLoading] = useState(false);
    const [randomNumber, setRandomNumber] = useState(0);
    const [completeOfferResponse, setCompleteOfferResponse] = useState({});

    const offerUniqueIdRef = useRef(null);

    const getUserCompletedOffers = async () => {
        setCompleteOffersLoading(true)
        const userCompletedOffers = await apiGetUserOfferCompleteHistory(user)
        setCompleteOffersData(userCompletedOffers.offers)
        setCompleteOffersLoading(false)
    }

    useEffect(() => {
        getUserCompletedOffers()
        setRandomNumber(Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000)
    }, [])


    const onSearchOffer = async (e) => {
        setOffer({})
        setOfferSearchLoading(true)
        setCompleteOfferResponse({})
        const uniqueOfferId = offerUniqueIdRef.current.value
        const res = await apiGetOfferById({ uniqueOfferId })
        setOfferSearchLoading(false)
        setOffer(res?.offer[0] ?? {});
    }

    const onOfferComplete = async (e) => {
        setLoadingBtn(true);
        const sessionId = md5(`${user}${offer.UniqueID}${randomNumber}`);
        
        const res = await apiCompleteUserOffer(user, offer.UniqueID, sessionId)
        setLoadingBtn(false);
        setCompleteOfferResponse(res)
        getUserCompletedOffers()
        
    }

    return (
        <>
            <FormControl sx={{ mb: 1, mt: 2, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="search-offer">Search Offer UniqueID</InputLabel>
                <OutlinedInput
                    id="search-offer"
                    type="text"
                    fullWidth
                    inputRef={offerUniqueIdRef}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={onSearchOffer}
                                edge="end"
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Search Offer UniqueID"
                />
            </FormControl>

            {Object.keys(offer).length > 0 && !completeOfferResponse.status && (
                <Box sx={{ mb: 3 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={!offerSelected || offer.ID}
                        exclusive
                        onChange={() => setOfferSelected(prev => !prev)}
                        aria-label="Platform"
                        fullWidth
                    >
                        <ToggleButton value={offer.ID} fullWidth sx={{ textTransform: "none", width: '100%' }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={10} display="flex" justifyContent="space-between" sx={{ textAlign: "left" }}>
                                    <Box width={20}>{offerSelected && (<CheckCircleIcon color='success' />)}</Box>
                                    <Box>
                                        <b>Offer UniqueID</b>
                                        <div>{offer.UniqueID}</div>
                                    </Box>
                                    <Box>
                                        <b>Offer ID</b>
                                        <div>{offer.ID}</div>
                                    </Box>
                                    <Box>
                                        <b>App Name</b>
                                        <div>{offer.AppName}</div>
                                    </Box>
                                    <Box>
                                        <b>Provider Name</b>
                                        <div>{offer.ProviderName}</div>
                                    </Box>
                                    <Box>
                                        <b>Coins</b>
                                        <div>{offer.Coins}</div>
                                    </Box>
                                    <Box>
                                        <b>System Payout</b>
                                        <div>{offer.SystemPayout}</div>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    {offerSelected && (
                                        <LoadingButton
                                            onClick={onOfferComplete}
                                            endIcon={<SendIcon />}
                                            loading={loadingBtn}
                                            loadingPosition="end"
                                            variant="contained"
                                        >
                                            <span>Send</span>
                                        </LoadingButton>
                                    )}
                                </Grid>
                            </Grid>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}

            {completeOfferResponse.message && (
                <Paper elevation={3} square={false} sx={{padding: 1, color: (completeOfferResponse.status == 'success' ? '#008b12' : '#ff3333')}} >{completeOfferResponse.message}</Paper>
            )}


            {offerSearchLoading
                ? <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                : <Divider sx={{ borderBottomWidth: 4 }} />
            }

            <Box sx={{ p: 2, textAlign: 'left' }}>
                <Divider>
                    <Chip label="User Postback History" size="small" />
                </Divider>
                <DataTable
                    tableData={completeOffersData}
                    columns={completeOffersColumns}
                    filter={completeOffersFilter}
                    loading={completeOffersLoading}
                />
            </Box>

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            {/* <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.add}</button> */}
        </>
    );
}
