import React from "react";

export function ShineBorder({
    borderRadius = 8,
    borderWidth = 2,
    duration =  12,
    colors = ["#d7d6ff", "#b3ffb6", "#98fff6fc", "#eaffb5",],
    className = "",
    children,
}) {
    // Convert colors array to gradient if needed
    const gradientColor =
        Array.isArray(colors) ? colors.join(", ") : colors;

    return (
        <div
            style={{
                position: 'relative',
                display: "inline-block",
                borderRadius: `${borderRadius}px`,
                padding: `${borderWidth}px`,
            }}
            className={className}
        >
            {/* Border Effect */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: `${borderRadius}px`,
                    background: `radial-gradient(circle, transparent, ${gradientColor}, transparent)`,
                    backgroundSize: "300% 300%",
                    animation: `shine ${duration}s linear infinite`,
                    pointerEvents: "none",
                    maskImage: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                }}
            ></div>
            <div
                style={{
                    position: "relative",
                    padding: 2,
                    borderRadius: `${borderRadius}px`,
                    background: "linear-gradient(155deg, #e6eaff, #faf2ff, #ffffff)",
                }}
            >
                {children}
            </div>
        </div>
    );
}
