import React, { useMemo, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, MenuItem, Select } from '@mui/material'
import GridForm from 'components/gridList/Grid_Form'
import { Waypoint } from 'react-waypoint'
import Images from 'utils/Images'
import { Tabs, Tab } from '@mui/material';
import OffersDoneValidateButton from '../buttons/Offers_Done_Validate_Button.js'
import Alerts from 'utils/Alerts.js'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const content = require('content.json');
const validateButtonText = content.validate;

function LinkTab(props) {
  return (
    <Tab
      sx={{
        "&.Mui-selected": {
          color: '#1B1C1E',
          background: "#FFFFFF",
          border: "1px solid #141629",
          borderRadius: "8px",
        },
        "&": {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '170%',
          textAlign: 'center',
          textTransform: 'Capitalize',
          color: '#1B1C1E',
          padding: '5px 16px',
          minHeight: 0
        },
      }}
      component="div"
      {...props}
    />
  );
}

const GridList = ({
  actions,
  columns,
  items,
  searchBy,
  switchValue,
  setSwitchValue,
  CellRowComponent,
  periodFilter,
  handleWithdrawals,
  handleAction,
  handleChoose,
  handleOpenModal,
  handleScroll,
  handleSortChange,
  handleSearchChange,
  handleOfferChange,
  selectedUser,
  statusFilter,
  providerFilter,
  platformFilter,
  countriesFilter,
  columnFilter,
  offersDoneFilter,
  offersDoneFilterChange,
  background,
  updateItems,
  setItems,
  handleMultiModal,
  error,
  height,
  onNewOffer,
}) => {
  const [sortOptions, setSortOptions] = useState({ option: '', order: 'ASC' })
  const isOffersOrDone = searchBy === "Offers Done" || searchBy === "Offers";

  const handleChange = (event, newValue) => {
    setSwitchValue(newValue);
  };

  const handleSortOptionsChange = option => {
    setSortOptions(prev => {
      return { option: option, order: prev['order'] === 'ASC' ? 'DESC' : 'ASC' }
    })
    handleSortChange({ sortBy: option, sortDirection: sortOptions.order })
  }

  return (
    <div id='grid-container' className="grid-container" style={{height: `${height}%`}}>
      <div
        className={`grid-list_flex background-${background}`}
        style={{
          alignItems: `${!items?.length ? 'center' : 'start'}`,
          minWidth: `${6 * columns.length}vw`,
        }}
      >
        
        <div
          style={{
            alignItems: `${!items?.length ? '' : 'start'}`,
          }}
          className={searchBy === "Offers Done" ? "grid-list_search new w-full" : "grid-list_search"}>
            <div className="grid-list_search-heading"  >
              <div className='flex w-full justify-between'>
                <div className='flex gap-10'>
                  <img src={Images.imageURL('rectangle.svg')} alt="" />
                  <h3>{searchBy}</h3>
                </div>
                {
                  switchValue ?
                  <OffersDoneValidateButton
                    text={validateButtonText}
                    clickHandler={() => Alerts.showValidateAlert(providerFilter)}
                  />  :
                  null
                }
              </div>
            </div>
            {
              searchBy === "Users" || searchBy === "Withdrawals" ?
                <div className='flex gap-10'>
                  {selectedUser && (
                    <FormControl size="small"  sx={{ minWidth: 130, textAlign:'left'}}>
                      <InputLabel id="action-by" sx={{ minWidth: 130 }} >Action</InputLabel>
                      <Select
                        id="action-by"
                        size="small"
                        onChange={event => {
                          handleAction(event.target.value)
                        }}
                        label="Action"
                      >
                        {Object.keys(actions).map((item, idx) => (
                          <MenuItem value={item} key={idx}>
                            {actions[item]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                )}
                <GridForm
                  updateItems={updateItems}
                    searchBy={searchBy}
                    columnFilter={columnFilter}
                    periodFilter={periodFilter}
                    handleSearchChange={handleSearchChange}
                  />
                </div> :
                null
            }
            {
              searchBy === "Offers" ?
                <div className='flex gap-10'>
                <GridForm
                  updateItems={updateItems}
                    searchBy={searchBy}
                    statusFilter={statusFilter}
                    platformFilter={platformFilter}
                    countriesFilter={countriesFilter}
                    columnFilter={columnFilter}

                    bestOffersFilter={true}
                    handleSearchChange={handleSearchChange}
                    onNewOffer={onNewOffer}
                  />
                </div> :
                null
            }
            {
              searchBy === "Offers Done" ?
                <div className='flex gap-10 justify-between'>
                  <Tabs 
                    sx={{
                      "&": {
                        background: '#F3F3F3',
                        borderRadius: '8px',
                        minHeight: '0',
                      },
                      "& *": {
                        minHeight: '0',
                      }
                    }}
                    TabIndicatorProps={{
                      style: { display: 'none' }
                    }}
                    value={switchValue} 
                    onChange={handleChange} 
                    aria-label="nav tabs example"
                  >
                    <LinkTab label="Stats" />
                    <LinkTab label="Admin actions" />
                  </Tabs> 
                <GridForm
                  updateItems={updateItems}
                    searchBy={searchBy}
                    offersDoneFilter={offersDoneFilter}
                    offersDoneFilterChange={offersDoneFilterChange}
                    providerFilter={providerFilter}
                    dateSelection={true}
                    handleSearchChange={handleSearchChange}
                  />
                </div> :
                null
            }
        </div>
        

        <div
          className={`grid-list_header ${isOffersOrDone ? "centered" : ""} background-${background}`}
          
        >
          {columns?.map((col, idx) => {
            const width = col.width ? {width: `${col.width}px`} : {};
            return (
              <div
                className="grid-list_header-cell"
                onClick={() => handleSortOptionsChange(col.field)}
                key={idx}
                style={width}
              >
                {col?.filterable ? (
                  <>
                    <p>{col.headerName} </p>
                    <KeyboardArrowDownIcon
                      className="grid-list_header-cell_arrow"
                      style={{
                        transform:
                          sortOptions['option'] === col.field && sortOptions['order'] === 'DESC'
                            ? 'rotate(180deg)'
                            : 'rotate(0)',
                        transition: 'transform 0.5s',
                      }}
                    />
                  </>
                ) : (
                  <p>{col?.headerName}</p>
                )}
              </div>
          )})}
        </div>
        {items?.length > 0 ? (
          items.map(item => (
            <div
              disabled
              className={`grid-list_table-row 
                ${isOffersOrDone ? "centered" : ""}
                ${item?.Status==="removed by provider" ?  "disabled" : ""}
                ${[5,6].includes(item.OfferTypeID) ? "system-offer disabled" : ""}
              `}
              style={{  }}
              key={item.ID || Math.random()}
            >
              <CellRowComponent
                handleChoose={handleChoose}
                columns={columns}
                item={item}
                handleWithdrawals={handleWithdrawals}
                handleOpenModal={handleOpenModal}
                handleOfferChange ={handleOfferChange}
                selectedUser={selectedUser}
                searchBy={searchBy}
                updateItems={updateItems}
                setItems={setItems}
                handleMultiModal={handleMultiModal}
              />
            </div>
          ))
        ) : (
          <div className="grid-list_no-results margin-top-10">
            <p>No results,{error}</p>
          </div>
        )}
      </div>
      <Waypoint onEnter={handleScroll} />
    </div>
  )
}

export default GridList;